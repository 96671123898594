<template>
  <!-- Error page-->
  <b-card>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="text-left">
          <div class="payment-status">
            <h2 class="mb-1">
              Payment Status:
            </h2>
            <h2
              v-if="customer.paymentStatus === 'paid'"
              class="mb-1"
            >
              <strong class="paid">{{ customer.paymentStatus }}</strong>, thank you! ❤️❤️
            </h2>
            <h2
              v-else
              class="mb-1"
            >
              <strong class="error">{{ customer.paymentStatus }}</strong> sorry, something went wrong.
            </h2>
          </div>
          <p class="text-max-width">
            This page is only relevant if you have been redirected after your payment.
            If you're seeing "paid", then everything worked as expected.
            If the payment status shows something else please get in contact with us and quote your customer ID:
          </p>
          <p>
            {{ customer.id }}
          </p>
          <a
            href="#"
          >contact us.</a>
          <b-card-img
            :src="imgUrl"
            fluid
            class="error-img"
          />
        </div>
      </div>
    </div>
  </b-card>
  <!-- / Error page-->
</template>

<script>
import { computed } from '@vue/composition-api'
import useStripe from '@/composables/stripe/useStripe'
import { BCard, BCardImg } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardImg,
  },
  setup() {
    const { getStripeSession, customerSession: customer } = useStripe()

    const urlParams = computed(() => new URLSearchParams(decodeURI(window.location.search)))
    const sessionId = computed(() => urlParams.value.get('session_id'))

    if (sessionId.value) {
      getStripeSession(sessionId.value)
    }

    return {
      getStripeSession,
      customer,
    }
  },
  data() {
    return {
      imgUrl: '',
    }
  },
}
</script>

<style lang="scss">
.error-img {
  border-radius: 1rem;
  max-width: 520px;
}
.text-max-width {
  max-width: 520px;
}

.payment-status {
  display: flex;
  gap: 1rem;
  .error {
    color: red;
  }
  .paid {
    color: green;
  }
}

</style>
